import React from "react"

import ActionButtons from "../Elements/ActionButtons"
import HealthSurveyForm from "./HealthSurvey/HealthSurveyForm"

const HealthSurvey = ({ backRoute }) => {
  return (
    <HealthSurveyForm>
      <ActionButtons
        hasCaptcha={true}
        submit={{ label: "Review Details" }}
        back={{ label: "Back", link: backRoute }}
      />
    </HealthSurveyForm>
  )
}

export default HealthSurvey
